import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import axios from 'axios'
import Echo from 'laravel-echo'
import Vapor from 'laravel-vapor'
import _ from 'lodash'
import Pusher from 'pusher-js'

window._ = _

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost:
        import.meta.env.VITE_PUSHER_HOST ??
        `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'us2'}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
})

window.Vapor = Vapor

if (['production', 'staging'].includes(import.meta.env.MODE)) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY || 'not-set',
        plugins: [new BugsnagPluginVue()],
        releaseStage: import.meta.env.VITE_APP_ENV,
        enabledReleaseStages: ['production', 'staging'],
        appVersion: __APP_VERSION__,
    })

    window.bugsnagVue = Bugsnag.getPlugin('vue')
}
