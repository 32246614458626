export interface DraggableBindings {
    active: boolean
    events: (data: {
        type: string
        pageX: number
        pageY: number
        top: number
        left: number
    }) => void
    container: HTMLElement
    scrollContainer: HTMLElement
    scrollX?: number
    scrollY?: number
    videoContainer?: HTMLElement
}

export const draggable = (
    element: HTMLElement,
    { value }: { value: DraggableBindings }
) => {
    const emit = (data: any) => value.events(data)
    const container: HTMLElement = value.container ?? element.parentNode
    const scrollContainer: HTMLElement =
        value.scrollContainer ?? element.parentNode

    let startX: number,
        startY: number,
        initialMouseX: number,
        initialMouseY: number,
        initialScrollX: number,
        initialScrollY: number

    const handleMouseMoveWithVideo = (e: MouseEvent | TouchEvent) => {
        const boundaryContainer = value.videoContainer ?? container
        const boundaryRect = boundaryContainer.getBoundingClientRect()
        const containerRect = container.getBoundingClientRect()

        const offsetX = boundaryRect.left - containerRect.left
        const offsetY = boundaryRect.top - containerRect.top

        let clientX: number
        let clientY: number

        if (e.type.startsWith('touch')) {
            clientX = (e as TouchEvent).touches[0].clientX
            clientY = (e as TouchEvent).touches[0].clientY
        } else {
            clientX = (e as MouseEvent).clientX
            clientY = (e as MouseEvent).clientY
        }

        const relativeX = clientX - containerRect.left
        const relativeY = clientY - containerRect.top

        const halfDotWidth = element.offsetWidth / 2
        const halfDotHeight = element.offsetHeight / 2

        const minX = offsetX - halfDotWidth
        const maxX = offsetX + boundaryRect.width - halfDotWidth
        const minY = offsetY - halfDotHeight
        const maxY = offsetY + boundaryRect.height - halfDotHeight

        const newX = Math.max(minX, Math.min(relativeX - halfDotWidth, maxX))
        const newY = Math.max(minY, Math.min(relativeY - halfDotHeight, maxY))

        element.style.left = `${newX}px`
        element.style.top = `${newY}px`

        emit({
            ...e,
            top: newY,
            left: newX,
            type: 'mousemove',
        })
    }

    const handleMouseMoveWithoutVideo = (e: MouseEvent) => {
        let width = container.offsetWidth
        let height = container.offsetHeight

        let dx =
            Math.max(e.pageX, 0) -
            initialMouseX -
            (scrollContainer.offsetLeft - initialScrollX)
        let dy =
            Math.max(e.pageY, 0) -
            initialMouseY -
            (scrollContainer.offsetTop - initialScrollY)

        let newX = Math.max(0, startX + dx)
        let newY = Math.max(0, startY + dy)

        element.style.top = `${newY}px`
        element.style.left = `${newX}px`

        if (newY + scrollContainer.offsetTop < 10) {
            emit({ type: 'topEdge' })
        } else if (newY + scrollContainer.offsetTop > height - 80) {
            emit({ type: 'bottomEdge' })
        }

        if (newX + scrollContainer.offsetLeft < 10) {
            emit({ type: 'leftEdge' })
        } else if (newX + scrollContainer.offsetLeft > width - 10) {
            emit({ type: 'rightEdge' })
        }

        emit({
            ...e,
            top: newY,
            left: newX,
            type: 'mousemove',
        })
    }

    const mousemove = (e: MouseEvent | TouchEvent) => {
        if (value.videoContainer) {
            handleMouseMoveWithVideo(e)
        } else {
            handleMouseMoveWithoutVideo(e as MouseEvent)
        }
    }

    const mousedown = (e: MouseEvent | TouchEvent) => {
        startX = element.offsetLeft
        startY = element.offsetTop
        initialMouseX =
            e.type === 'touchstart'
                ? (e as TouchEvent).touches[0].clientX
                : (e as MouseEvent).clientX
        initialMouseY =
            e.type === 'touchstart'
                ? (e as TouchEvent).touches[0].clientY
                : (e as MouseEvent).clientY
        initialScrollX = scrollContainer.offsetLeft
        initialScrollY = scrollContainer.offsetTop

        container.addEventListener('mousemove', mousemove)
        container.addEventListener('touchmove', mousemove as any)
        window.addEventListener('mouseup', mouseup)
        window.addEventListener('touchend', mouseup)
        emit(e)
    }

    const mouseup = (e: MouseEvent | TouchEvent): void => {
        container.removeEventListener('mousemove', mousemove)
        container.removeEventListener('touchmove', mousemove as any)
        window.removeEventListener('mouseup', mouseup)
        window.removeEventListener('touchend', mouseup)
        emit(e)
    }
    if (value.active) {
        element.addEventListener('mousedown', mousedown)
        element.addEventListener('touchstart', mousedown)
    }
}
