import tippy, { Props as Tippy } from 'tippy.js'
import 'tippy.js/themes/light.css'

export interface TooltipBindings extends Tippy {
    content: string
    allowHTML: boolean
    placement:
        | 'top'
        | 'bottom'
        | 'left'
        | 'right'
        | 'top-start'
        | 'top-end'
        | 'bottom-start'
        | 'bottom-end'
        | 'left-start'
        | 'left-end'
        | 'right-start'
        | 'right-end'
        | 'auto'
        | 'auto-start'
        | 'auto-end'
    arrow: boolean
    animation:
        | 'fade'
        | 'scale'
        | 'shift-away'
        | 'shift-toward'
        | 'perspective'
        | 'perspective-away'
        | 'perspective-toward'
        | 'none'
    trigger: 'click' | 'mouseenter' | 'mouseleave' | 'focus' | 'manual'
}

export const tooltip = (
    element: HTMLElement | Element,
    {
        value: { content, allowHTML, placement, arrow, trigger, theme = '' },
    }: { value: TooltipBindings }
): void => {
    tippy(element, {
        content,
        allowHTML,
        placement,
        arrow,
        trigger,
        theme,
    })
}
